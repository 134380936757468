import React, {Component} from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'

import './base.css'
import Hero from '../components/hero'
import Layout from '../layouts/layout'
import './404.css'


class NotFound extends React.Component {

  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const thisTitle = "404 Not Found"
    const heros = get(this, 'props.data.allContentfulHero.edges')

    const socialmedias = get(this, 'props.data.allContentfulSocialMedia.edges')

    return (
      <Layout location={this.props.location} socialmedias={socialmedias}>
        <Helmet title={`${thisTitle} | ${siteTitle}`} />

        <Link className="NotFoundHeroGroup" to="#">
            <Hero data={heros} />        
        </Link>
      </Layout>
    )
  }
}

export default NotFound

export const pageQuery = graphql`
  query NotFoundQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulHero(sort: { fields: [createdAt], order: ASC }, filter: {page: {eq: "404"}}) {
      edges {
        node {
          name
          description
          quote
          bg {
            fluid(maxWidth: 1000){
              sizes
              src
              srcSet
            }
          }
          createdAt
          page
        }
      }
    }
    allContentfulSocialMedia (sort: { fields: [orderRank, createdAt], order: ASC }) {
        edges {
          node {
            fontclass
            color
            url
            title
          }
        }
      }
  }
`